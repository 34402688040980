import { deepCompare } from 'assets/js/common';
import _, { isNil } from 'lodash';

export default function healMissingStrategySettings(exchanges) {
  if (Object.keys(exchanges).length < 1) {
    return false;
  }

  let healedExchanges = JSON.parse(JSON.stringify(exchanges));

  Object.keys(exchanges).forEach(exchange => {
    // check market type
    if (exchange === 'binance' || exchange === 'binanceus') {
      healedExchanges[exchange].market = 'spot';
    } else if (exchange === 'bitmex' || exchange === 'bitmex_testnet') {
      healedExchanges[exchange].market = 'futures';
    } else if (
      (exchange === 'binanceFutures' || exchange === 'dydx' || exchange === 'dydx4' || exchange === 'futures_gunthy') &&
      _.isNil(exchanges[exchange].market)
    ) {
      healedExchanges[exchange].market = 'futures';
    } else if (
      (exchange === 'binanceFutures' || exchange === 'dydx' || exchange === 'dydx4' || exchange === 'futures_gunthy') &&
      exchanges[exchange].market.includes('spot')
    ) {
      healedExchanges[exchange].market = 'futures';
    }

    
    // check missing trading fees
    if (_.isNil(exchanges[exchange].TRADING_FEES)) {
      healedExchanges[exchange].TRADING_FEES = 0.25;
    }
  });

  if (!deepCompare(exchanges, healedExchanges)) {
    return healedExchanges;
  } else {
    return false;
  }
}
